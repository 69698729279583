@import "./tailwind-preflight.scss";
@tailwind components;
@tailwind utilities;

@layer utilities {
  .flex-box {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
}